<template>
  <div class="main-content">
    <b-container class="mt-3">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">
                {{ $t("staff.title") }}
              </h4>
              <!-- Select -->
              <form>
                <Select2
                  v-model="sortOrder"
                  :options="sortOrderOptions"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"                         
                />               
              </form>

              <b-button variant="primary" @click="createInvite" size="sm">
                {{ $t("staff.invite") }}
              </b-button>
            </div>
            <div class="card-header">
              <!-- Form -->
              <form>
                <div class="input-group input-group-flush input-group-merge">
                  <!-- Input -->

                  <b-form-input
                    :placeholder="$t('common.search')"
                    v-model="filter"
                    class="form-control form-control-prepended search"
                    type="search"
                  ></b-form-input>

                  <!-- Prepend -->
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <span class="fe fe-search"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body">
              <zs-list-transition :showLoader="showLoader">
                <template v-slot:content>
                  <employee-list-item
                    v-for="(employee, index) in getLocationMembersFiltered"
                    v-bind:key="index"
                    :employee="employee"
                  />
                </template>
                <template v-slot:empty>
                  <empty-list :title="$t('staff.no_staff')" />
                </template>
              </zs-list-transition>
            </div>
          </div>
          <!-- modals -->
          <div id="modals">
            <zs-company-delete-modal />
            <zs-delete-location-user-modal />
            <zs-location-invite-modal
              :companyId="$route.params.companyId"
              :locationId="$route.params.locationId"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DeleteCompanyModal from "@/components/modals/DeleteCompany";
import InviteLocationEmployeeModal from "@/components/modals/InviteLocationEmployee";
import DeleteLocationUser from "@/components/modals/DeleteLocationUser";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";

import ListTransition from "@/components/common/ListTransition";
import EmployeeListItem from "@/components/user/EmployeeListItem";

import { definedRoles, definedKeyRolesIdsArray } from "@/acl/roles";

import { mapGetters } from "vuex";

import { LOCATION_MEMBERS_GET } from "@/store/types/members";

import { USER_DECLINE_INVITE, USER_GET_ROLES } from "@/store/types/user";

import {
  M_COMPANY_INVITE_FORM,
  M_LOCATION_USER_DELETE,
  M_COMPANY_DELETE,
} from "@/components/modals/types";

import modalMixin from "@/mixins/modalMixin";

export default {
  mixins: [modalMixin],
  data() {
    return {
      filter: "",
      sortOrder: 1,
      sortOrderOptions: [ 
        { id: 1, text: "Asc"},
        { id: 2, text: "Desc"},
      ],
      showLoader: true,
    };
  },
  computed: {
    ...mapGetters(["getLocationMembers"]),
    getLocationMembersFiltered() {
      return this.getLocationMembers
        .filter((item) => !definedKeyRolesIdsArray().includes(item.Role.Id))
        .filter((item) =>
          (item.User.FirstName + " " + item.User.LastName)
            .toLowerCase()
            .includes(this.filter.toLowerCase())
        )
        .sort((i1, i2) => {
          if (this.sortOrder == 1) {
            return (i1.User.FirstName + i1.User.LastName).toUpperCase() >
              (i2.User.FirstName + i2.User.LastName).toUpperCase()
              ? 1
              : -1;
          } else {
            return i1.User.FirstName + i1.User.LastName <
              (i2.User.FirstName + i2.User.LastName).toUpperCase()
              ? 1
              : -1;
          }
        });
    },
  },
  created() {
    this.$store.dispatch(LOCATION_MEMBERS_GET, this.$route.params.locationId);
  },
  methods: {
    createInvite() {
      this.showModal({ staffInvite: null }, M_COMPANY_INVITE_FORM);
    },
    deleteUser(user) {
      user.User["CompanyId"] = user.Location.CompanyId;
      user.User["Location"] = user.Location;
      this.showModal({ user: user.User }, M_LOCATION_USER_DELETE);
    },
    deleteInvite(invite) {
      this.$store.dispatch(USER_DECLINE_INVITE, invite.Id).then(() => {
        this.$store.dispatch(LOCATION_MEMBERS_GET, invite.Location.Id);
      });
    },
    getRoleObject(roleName) {
      return definedRoles[roleName];
    },
  },
  components: {
    "zs-company-delete-modal": DeleteCompanyModal,
    "zs-location-invite-modal": InviteLocationEmployeeModal,
    "zs-delete-location-user-modal": DeleteLocationUser,
    "zs-list-transition": ListTransition,
    EmployeeListItem,
    EmptyList,
    Select2,
  },
};
</script>
